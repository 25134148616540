<template>
    <div class="wrapper">
        <img src='https://static.yihu365.cn/img/h5Img/assets/img/icon/success.png' class='success_icon'/>
        <div class="box_txt">
            <p class="succ_txt">{{msg}}</p>
        </div>
    </div>
</template>

<script>
    import {useRoute} from "vue-router";
    import {ref} from "vue";

    export default {
        name: "bindSuccess",
        setup() {
            const route = useRoute();
            let msg = ref('');
            if(route.query.type == 1){
                msg.value="您已绑定,无需再次绑定";
            }else{
                msg.value="恭喜您绑定成功";
            }
            return {
                route,
                msg
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 200px;

        .success_icon {
            width: 100px;
            height: 100px;
        }

        .succ_txt {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-size: 32px;
            color: #313131;
        }

    }
</style>
